import { ReactNode, useState, useContext, useMemo, useEffect, Children, cloneElement, isValidElement } from 'react';
import Select from 'react-select';
import { Accordion, Card, SplitButton } from 'react-bootstrap';
import clsJn from '@property-folders/common/util/classNameJoin';
import { map, filter, orderBy } from 'lodash';
import './Wizard.scss';
import { useMultiWatchVariation } from '../../hooks/useMultiWatchVariation';
import useDetectSticky from '../../hooks/useDetectSticky.js';
import { ButtonWithOfflineTooltip, DropdownItemWithOfflineTooltip, TooltipWhenRequired } from '../TooltipWhenDisabled';
import { Predicate } from '@property-folders/common/predicate';
import { WizardDisplayContext } from '../../context/WizardContexts';
import { IconPacks } from '@property-folders/common/types/IconProps';
import './WizardStepPage.scss';

export type HeaderAction = {
  label?: string,
  tooltip?: string,
  disabled?: boolean,
  isPrimary?: boolean,
  onClick: () => void,
  disableIfOffline?: boolean
};

export type HeaderActionItem = HeaderAction & {
  value: number;
};

export type SetHeaderActionsFn = (f: (a: { [key:string]: HeaderAction }) => void) => void;
export type WizardSidebarSubsetProps = {
  name: string,
  label: string | JSX.Element,
  icon: string,
  iconPack?: IconPacks,
  embedded?: boolean
};
export type WizardStepPageOtherProps = {
  children?: ReactNode,
  percentComplete?: number
  isValid?: boolean
  pageOnlyLabel?: string
  headerContent?: ReactNode
  useSearchInHeader?: boolean;
  pdfFocus?: string
  defaultHeaderActions?: { [key:string]: HeaderAction }
  onImport?: () => void;
  variationWatchPaths?: string[][]
  variationDeterminationCallback?: (()=>boolean)[]
  requiredTooltip?: string
  generalFocusClass?: string
  sectionErrorText?: string
  independentCollapsible?: boolean
  flush?: boolean

};

export type StandaloneWizardStepPageProps = (
  WizardSidebarSubsetProps & WizardStepPageOtherProps
  );

export type WizardStepPageProps = StandaloneWizardStepPageProps | {
      // Used in circumstances that the producing component returns a list of WizardStepPages
      // wrapped in a React Fragment, and as a result we can't extract the label data from it
      // otherwise. In this case, we expect that the element uses this declared list of dragged-components
      // in the order specified when producing the list of Wizard Step Pages
      wizardSectionProps: WizardSidebarSubsetProps[]
    };

// A lighter grey than the one in bootstrap, because with shadows it makes it look darker than the
// background 'rgb(241, 245, 250)'

function recursiveMap(children, fn) {
  return Children.map(children, child => {
    if (!isValidElement(child)) {
      return child;
    }

    if (child.props.children) {
      child = cloneElement(child, {
        children: recursiveMap(child.props.children, fn)
      });
    }

    return fn(child);
  });
}

/**
 *
 * @param param0.pageOnlyLabel Title to show on the page itself, overriding label, which is also
 *  used to generate the sidebar labels
 * @returns
 */
export const WizardStepPage = ({
  sectionErrorText,
  requiredTooltip,
  name,
  children,
  label,
  pageOnlyLabel,
  headerContent,
  useSearchInHeader,
  pdfFocus,
  defaultHeaderActions,
  variationWatchPaths,
  embedded,
  generalFocusClass,
  independentCollapsible = false,
  flush = false
}: WizardStepPageProps): JSX.Element => {
  const { hasVaried, variationsMode, expandAll } = useMultiWatchVariation(variationWatchPaths||[]);
  const accordionEnable = variationsMode || independentCollapsible;
  const [isSticky, ref] = useDetectSticky();
  const extraProps: {'data-focus-path'?: string} = {};
  const ContainerRender = accordionEnable ? Accordion : Card;
  const HeaderRender = accordionEnable ? Accordion.Header : Card.Header;
  const BodyRender = accordionEnable ? Accordion.Body : Card.Body;
  const { showFocusErrors, focusErrList } = useContext(WizardDisplayContext);
  // variationWatchPaths is string[][] | undefined.
  // note: if we start needing to match nested paths then we might need better path-matching logic
  const flatWatchPaths = useMemo(() => (variationWatchPaths || []).map(p => p.join('_')), [variationWatchPaths]);
  const hasErrors = useMemo(() => {
    return flatWatchPaths.length
      ? !!(focusErrList
        .map(err => err.replace('error-path-focus-', ''))
        .filter(err => flatWatchPaths.some(p => p === err))
        .length)
      : false;
  }, [flatWatchPaths, focusErrList]);

  const showWizardSectionError = showFocusErrors && focusErrList.includes(generalFocusClass);

  if (pdfFocus) {
    extraProps['data-focus-path'] = pdfFocus;
  }

  const [headerActions, setHeaderActions] = useState<{ key: HeaderAction }|undefined>(undefined);
  const [accordionOpen, setAccordionOpen] = useState(!accordionEnable || hasVaried);

  useEffect(() => {
    if (!expandAll) return;

    const handler = (value: boolean) => {
      setAccordionOpen(value);
    };

    expandAll.on('changed', handler);

    return () => {
      expandAll.off('changed', handler);
    };
  }, [expandAll]);

  //inject setHeaderActions function into children
  const childrenWithRef = useMemo(() => recursiveMap(
    [
      showWizardSectionError
        ? <div className='d-block invalid-feedback mb-3'>{sectionErrorText}</div>
        : null,
      ...(Array.isArray(children)? children : [children])
    ].filter(Predicate.isNotNullish),
    child => cloneElement(child, { ...child.type !== 'div' && { setHeaderActions } })
  ), [showWizardSectionError, sectionErrorText, children]);

  let headerButtons;
  const filteredActions: HeaderActionItem[] = filter({ ...defaultHeaderActions, ...headerActions }, v => !!v);
  if (filteredActions) {
    // Order and add value to each item for `<Select>` to use as key
    const sortedActions: HeaderActionItem[] = orderBy(map(filteredActions, (v, idx) => ({ ...v, value: idx })), [a => a.isPrimary, a => a.label], ['desc', 'asc']);

    if (sortedActions.length == 1) {
      headerButtons = sortedActions.map(v => <ButtonWithOfflineTooltip offlineCheckDisabled={!v.disableIfOffline} key={v.label} onClick={v.onClick} title={v.tooltip} variant='light' disabled={v.disabled} className={clsJn('btn-list-action btn-list-add')}>{v.label}</ButtonWithOfflineTooltip>);
    } else {
      const primaryAction = sortedActions[0];

      headerButtons = primaryAction && (
        useSearchInHeader
          ? (
            <div className={'dropdown-select'} onClick={e=>e.stopPropagation()} onMouseDown={e=>e.stopPropagation()}>
              <Select
                options={sortedActions}
                placeholder='Template'
                defaultValue={primaryAction}
                classNames={{
                  container: () => 'dropdown-select',
                  control: () => 'dropdown-select-control',
                  menu: () => 'dropdown-select-menu',
                  option: () => 'dropdown-select-option'
                }}
                onChange={(newValue) => newValue && newValue.onClick()}
                isSearchable
              />
            </div>
          ) : (
            <div onClick={e=> e.stopPropagation()}>
              <SplitButton variant='light' key='primary' title={primaryAction?.label} onClick={primaryAction?.onClick} className={primaryAction?.disabled ? 'primary-disabled' : ''}>
                {sortedActions.map(v => <DropdownItemWithOfflineTooltip offlineCheckEnabled={v.disableIfOffline} key={v?.label} eventKey={v?.label} onClick={v?.onClick} title={v.tooltip} disabled={v?.disabled}>{v?.label}</DropdownItemWithOfflineTooltip>)}
              </SplitButton>
            </div>
          )
      );
    }
  }

  const forceOpen = (accordionOpen || (accordionEnable && (hasVaried || hasErrors))) ? { activeKey: '0' } : null;

  const itemContents = <>
    <HeaderRender className={clsJn('bg-white accordion-card-header', (hasVaried || hasErrors) && 'force-open', isSticky && 'stuck')} ref={ref} style={{ position: 'sticky', top: '-1px', zIndex: 5 }}>
      <div className='d-flex flex-row justify-content-between w-100 align-items-center'>

        <TooltipWhenRequired title={requiredTooltip} required={!!requiredTooltip}>
          <div className='title'>
            <span className='fs-3'>{pageOnlyLabel || label}</span>
            {requiredTooltip ? <sup className='fs-5' style={{ color: 'red' }}> *</sup> : undefined}
          </div>
        </TooltipWhenRequired>

        <div className='d-flex align-items-center flex-grow-1 justify-content-end ps-3'>
          {(accordionOpen || forceOpen) && headerButtons && <div className={'d-flex flex-row flex-grow-1 align-items-center justify-content-end gap-2'}>{headerButtons}</div>}
          {headerContent && <div className={'ms-2 d-flex flex-row flex-grow-0 align-items-center justify-content-end gap-2'}>{headerContent}</div>}
          {/*We want this separate otherwise the 'underline will span the spacing as well'*/}
          {hasVaried && headerContent && <div className='ps-3'></div>}
          {hasVaried && <div className='ms-2 fs-5 varied-control'>Modified</div>}
        </div>
      </div>
    </HeaderRender>

    <BodyRender className={clsJn('card-body', flush ? 'p-0' : 'p-3')}>
      {childrenWithRef}
    </BodyRender>
  </>;

  return (
    <ContainerRender
      {...forceOpen}
      id={name}
      className={clsJn(generalFocusClass, 'card wiz-card bg-white', embedded ? 'embedded' : 'mx-0 mx-sm-2 mx-md-4 shadow', pdfFocus && 'scrollspy-target')}
      {...extraProps}
      onSelect={(key) => {
        setAccordionOpen(key != null);
      }}
    >
      {accordionEnable
        ?<Accordion.Item eventKey='0'>
          {itemContents}
        </Accordion.Item>
        :itemContents
      }
    </ContainerRender>
  );
};
